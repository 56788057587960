import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import SessionStore from '../State/SessionStore'
import { makeRequest } from '../Queries/makeRequest'
import { usingNewLogin } from '../State/Permissions/HasPermissions'

export default observer(() => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)
    useEffect(() => {
        if (usingNewLogin()) {
            const handleAuth = async () => {
                const urlParams = new URLSearchParams(window.location.search)
                const code = urlParams.get('code')
                if (!code) {
                    try {
                        const { data } = await makeRequest({
                            path: '/auth/mobile',
                            method: 'GET',
                            baseURL: process.env.REACT_APP_NODE_SERVER_URL,
                        })
                        if (!data || !data.url) {
                            throw new Error('Failed to redirect!')
                        }
                        window.location.replace(data.url)
                    } catch (error) {
                        setError('Failed to initiate login. Please try again.')
                    }
                } else {
                    try {
                        await SessionStore.login({ code })
                        // Destroy code search param after login
                        const newUrl =
                            window.location.pathname + window.location.hash
                        window.history.replaceState({}, document.title, newUrl)
                        window.location.reload()
                    } catch (error) {
                        setError(
                            error?.response?.data?.message ||
                                'An error occurred during login'
                        )
                    }
                }
            }

            handleAuth()
        }
    }, [])

    const retryLogin = () => {
        // Delete the code search param and reload the page
        const newUrl = window.location.pathname + window.location.hash
        window.history.replaceState({}, document.title, newUrl)
        window.location.reload()
    }

    return (
        <div>
            <div style={{ textAlign: 'center' }}>
                <img
                    src={
                        new URL(
                            '/public/coincraft_logo_130.png',
                            import.meta.url
                        )
                    }
                    alt="Coincraft Logo"
                />
            </div>
            <div style={{ textAlign: 'center' }} className="login">
                {usingNewLogin() ? (
                    <>
                        {error ? (
                            <>
                                <p style={{ color: 'red' }}>{error}</p>
                                <button
                                    className="add-entry-button project-btn"
                                    style={{ marginTop: '4rem' }}
                                    onClick={retryLogin}
                                >
                                    Retry Login
                                </button>
                            </>
                        ) : (
                            'Redirecting...'
                        )}
                    </>
                ) : (
                    <form>
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        <button
                            className="sign-in-button project-btn"
                            onClick={(e) => {
                                e.preventDefault()
                                SessionStore.login({ email, password })
                            }}
                        >
                            Sign In
                        </button>
                    </form>
                )}
            </div>
        </div>
    )
})
